import qs from 'qs';

import { isIOS } from '@eaphone/env';
import { captureException } from '@eaphone/sentry';

const initUrl = document.URL;

export function removeCode(url) {
  const [path, query] = url.split('?');
  const { code, state, ...rest } = qs.parse(query);

  return [path, qs.stringify(rest)].filter(Boolean).join('?');
}

export function wxConfig(getSignature, { openTagList, jsApiList }) {
  const entryUrl = isIOS ? removeCode(initUrl) : document.URL;

  return getSignature(entryUrl)
    .then((data) => {
      wx.config({
        // debug: true,
        openTagList,
        jsApiList,
        ...data,
      });
    })
    .catch((error) => {
      captureException(error, {
        tags: { scene: 'getSignature' },
      });
      throw error;
    });
}
